import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import UploadForm from "../../../Components/Forms/uploadGallery";
import SortableComponent from "../../../Components/Gallery/sort";
import FooterForm from "../../../Components/Lib/FooterForm";
import { renderField } from "../../../Components/Forms/renders";

const SyncValidationForm = ({ handleSubmit, item, dispatch }) => {
  const [visible, setVisible] = useState(false); // State voor de Rodal-modal

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const setUploadsImages = (images) => {
    dispatch(change("item_edit_media", "images", images));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <h4 className="info-text">Media gegevens.</h4>
        </div>
      </div>

      <div className="box">
        <div className="box with-border">
          <h3 className="box-title">Klik hier om de foto's te uploaden </h3>
          <button type="button" className="btn btn-primary btn float-right uploadbtn" onClick={show}>
            Upload bestanden
          </button>
        </div>
        <div className="box-body">
          <div className="box">
            <Field name="images" component={SortableComponent} />
          </div>

          <Rodal visible={visible} onClose={hide} width={800} height={500}>
            <Field name="imagesupload" ItemId={item._id} setUploadsImages={setUploadsImages}  onClose={hide} component={UploadForm} label="Kies bestanden" isModal={true} />
          </Rodal>
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Youtube Video</h3>
        </div>
        <div className="box-body">
          <Field name="media.video.url" label="URL van Youtube Video" component={renderField} />
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Skyfocus</h3>
        </div>
        <div className="box-body">
          <Field name="media.tours.url" label="URL van skyfocus" component={renderField} />
        </div>
      </div>

      <FooterForm />
    </form>
  );
};
const ReduxFormSensorEdit = reduxForm({
  form: "item_edit_media", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.items.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);

