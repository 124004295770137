import React, { useState, useEffect } from "react";
import Switch from "react-switch";

const SwitcButton = ({ label, input, meta: { touched, error, warning } }, defaultValue) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (input.value !== "") {
      setValue(input.value);
    } else if (defaultValue) {
      setValue(defaultValue);
    }
  }, [input.value, defaultValue]);

  const handleSelect = (newValue) => {
    setValue(newValue);
    input.onChange(newValue);
  };
  if (label) {
    return (
      <div>
        <label>{label}</label>
        <div>
          <Switch onChange={handleSelect} checked={value} />
        </div>
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  } else {
    return (
      <div>
        <Switch onChange={handleSelect} checked={value} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }
};

export default SwitcButton;
