import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField, renderAFieldDisabled, renderselect } from "../../../Components/Forms/renders";
import NDTRCTags from "../../../Components/Forms/ndtrctags";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import switcbutton from "../../../Components/Forms/switch";

const LogboekFormWebsite = ({ handleSubmit, dispatch }) => {
  const onChangeInput = (e) => {
    const newSlug = e.target.value
      .toLowerCase()
      .replace(/[^\w\s-]+/g, "")
      .replace(/\s+/g, "-");
    dispatch(change("categorie_edit", "slug", newSlug));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="box-body">
        <Field name="naam" type="input" onChange={onChangeInput} validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Titel van de arrangement" />
        <Field name="slug" type="text" component={renderAFieldDisabled} label="Slug van de titel" />
        <Field name="beschrijving.short" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderTextField} label="Korte omschrijving van de arrangement" />
        <Field name="beschrijving.long" type="textarea" component={Wysiwyg} label="Lange Omschrijving" className="form-control" normalize={(value) => value.toString("html")} />
        <Field
          name="aantal"
          type="number"
          component={renderselect}
          label="Aantal items (bedrijven)"
          className="form-control"
          options={{
            1: "1 item",
            2: "2 items",
            3: "3 items",
            4: "4 items",
            5: "5 items",
            6: "6 items",
            7: "7 items",
            8: "8 items",
            9: "9 items",
            10: "10 items",
          }}
        />
        <Field name="types" validate={required({ msg: "Types zijn verplicht" })} type="text" component={NDTRCTags} label="Welke tags hoort bij deze arrangement" className="form-control" />
        <Field name="actief" type="input" component={switcbutton} label="Is de arrangement actief" />
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({
  initialValues: state.arrangementen.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
