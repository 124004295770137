import React from "react";
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../Components/Forms/renders";
import MultiSelectDagen from "../../../Components/Forms/multiselect";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import UploadFrom from "../../../Components/Forms/uploadsingle";
import BeheerderForm from "./beheerders";
import SwitcButton from "../../../Components/Forms/switch";

const LogboekFormWebsite = ({ handleSubmit, formValues, initialValues }) => {
  const isPrijzenChecked = formValues && formValues.betaald;

  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">Gegevens van de hoofd eigenaar</div>
        <div className="card-body">
          <div>
            <Field name="owner.naam" type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Wie is de eigenaar van de ervaring " />
            <Field name="owner.telefoonnummer" type="text" component={renderField} label="Telefoonnummer eigenaar" />
            <Field name="owner.mobiel" type="text" component={renderField} label="Mobiele nummer eigenaar" />
            <Field name="owner.email" type="text" component={renderField} label="E-mail nummer eigenaar" />
            <Field name="owner.rekeningnummer" type="text" component={renderField} label="Rekeningnummer eigenaar (Iban)" />
            <Field name="owner.bank" type="text" component={renderField} label="Bank" />
            <Field name="owner.foto" type="input" component={UploadFrom} label="Upload foto van de eigenaar" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Beheren reservingen</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Bestigen</th>
                <th>Telefoonnummer</th>
                <th>Voorkeur</th>
              </tr>
            </thead>
            <FieldArray name={`beheerders`} component={BeheerderForm} />
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Bevestigen arragementen items</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Bevestigen </th>
                <th>Versturen reservering</th>
              </tr>
            </thead>
            <tbody>
              {initialValues.items.map((item, index) => (
                <tr key={index}>
                  <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>{item.naam}</td>
                  <td>
                    <Field key={index} name={`items[${index}].bevestigen`} component={SwitcButton} label="Moet de locatie eerst besvestigd worden" />
                  </td>
                  <td>
                    <Field key={index} name={`items[${index}].bevestiging`} component={SwitcButton} label="Versturen van de reservering" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Gegevens reservering</div>
        <div className="card-body">
          <div>
            <Field name="reservering.tijd" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Na hoeveel tijd vervalt de reserverig (uren) " />
            <Field
              name="reservering.betaalmogelijkheden"
              component={renderselect}
              options={{
                locatie: "Betaling wordt gedaan op locatie",
                direct: "Betaling wordt direct gedaan",
                bevestiging: "Betaling wordt gevraagd na bevestiging",
                vvv: "De betaling wordt gedaan via VVV",
              }}
              label="Hoe wordt de betaling gedaan"
            />
            <Field name="reservering.betalingen" component={MultiSelectDagen} options={["VVV", "Pin", "Contant", "Online"]} label="Hoe wordt de betaling gedaan" />
            <Field name="reservering.betalingtijd" type="number" component={renderField} label="Binnen hoeveel dagen moet de betaling worden gedaan" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Boekingsfee</div>
        <div className="card-body">
          <Field name="reservering.boekingsfee.value" type="number" component={renderField} label="Wat is de boekingsfee van de reservering" />
          <Field
            name="reservering.boekingsfee.regel"
            component={renderselect}
            options={{
              percentage: "Percentage van de boeking",
              vast: "Vast bedrag",
            }}
            label="Hoe wordt de betaling gedaan"
          />
        </div>
      </div>
      <div className="card-footer text-muted m-2">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({
  initialValues: state.arrangementen.item, // pull initial values from account reducer
  formValues: getFormValues("categorie_edit")(state), // get current form values
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
